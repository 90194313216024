import React from 'react';
import {useControllerProps} from '../../../domain/controllers/ControllerContext';
import {CartItem} from './CartItem/CartItem';

export enum CartItemsDataHook {
  'root' = 'CartItemsDataHook.root',
  'item' = 'CartItemsDataHook.item',
}
interface CartItemsProps {
  showQuantityBySettings: boolean;
  showThumbnailBySettings: boolean;
  shouldShowItemInfoBySettings: boolean;
  hideLastDivider?: boolean;
  onRemoveItem?: () => void;
  quantityClassName?: string;
  thumbnailClassName?: string;
  infoClassName?: string;
}
export const CartItems: React.FC<CartItemsProps> = ({
  showQuantityBySettings,
  showThumbnailBySettings,
  shouldShowItemInfoBySettings,
  hideLastDivider = false,
  onRemoveItem,
  quantityClassName,
  thumbnailClassName,
  infoClassName,
}) => {
  const {cart} = useControllerProps().cartStore;

  return (
    <div data-hook={CartItemsDataHook.root}>
      <ul>
        {cart.items.map((item, index) => (
          <li
            key={`cart-item-${item.cartItemId}`}
            data-hook={CartItemsDataHook.item}
            data-wix-line-item-id={item.cartItemId}>
            <CartItem
              showDivider={!hideLastDivider || index !== cart.items.length - 1}
              item={item}
              showQuantityBySettings={showQuantityBySettings}
              showThumbnailBySettings={showThumbnailBySettings}
              shouldShowItemInfoBySettings={shouldShowItemInfoBySettings}
              onRemoveItem={onRemoveItem}
              quantityClassName={quantityClassName}
              thumbnailClassName={thumbnailClassName}
              infoClassName={infoClassName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
